(() => {
  window.addEventListener('DOMContentLoaded', () => {
    loadBlock('banners-container', 'banners')
    loadBlock('offers-container', 'offers', 3)
    loadBlock('fullbanner-container', 'fullbanner')
    loadBlock('showcase-tab-highlights', 'showcase/highlights', 4)
    loadBlock('showcase-tab-bestsellers', 'showcase/bestsellers', null, 'bestseller/bestseller')
    loadBlock('showcase-tab-mostviewed', 'showcase/mostviewed', null, 'catalog/product_mostviewed')
  })

  const owlCarouselNavigationText = ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']

  const carousel = () => {
    jQuery('#offers-container ul').owlCarousel({
      itemsCustom: [[0, 1], [768, 2], [1025, 4]],
      lazyLoad: true,
      navigation: true,
      scrollPerPage: true,
      slideSpeed: 1000,
      theme: false,
      navigationText: owlCarouselNavigationText
    })

    jQuery('#manufacturers ul').owlCarousel({
      itemsCustom: [[0, 2], [768, 6], [1025, 8]],
      autoPlay: true,
      navigation: true,
      pagination: false,
      stopOnHover: true,
      theme: false,
      navigationText: owlCarouselNavigationText
    })
  }

  const alreadyRequested = []

  /**
   * loadBlock
   * @param {string} containerId id of html tag that will receive the response
   * @param {string} template phtml template used to render
   * @param {number} categoryId id of category, if any
   * @param {string} type type of block
   */
  const loadBlock = (containerId, template, categoryId = null, type = 'catalog/product_list') => {
    const url = '/getproducts/'

    if (alreadyRequested.indexOf(containerId) < 0) {
      jQuery.post({
        url,
        data: {
          alias: containerId,
          id: categoryId,
          template: `cms/home/ajax/${template}`,
          type: type
        },
        success (data) {
          document.getElementById(containerId).innerHTML = data
          carousel()
          alreadyRequested.push(containerId)
        }
      })
    }
  }

  jQuery('#blog ul').owlCarousel({
    itemsCustom: [[0, 1], [768, 2], [1025, 3]],
    autoPlay: true,
    navigation: true,
    pagination: false,
    stopOnHover: true,
    theme: false,
    navigationText: owlCarouselNavigationText
  })
})()
